<template>
  <div class="videoBox">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <video
      controls="controls"
      autoplay
      :src="prefix($route.query.play)"
    ></video>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { prefix } from "@/common/js/utils.js";
const onClickLeft = () => history.back();

const route = useRoute();
</script>

<style lang="less" scoped>
.videoBox {
  height: 100vh;
  position: relative;
  background-color: rgb(41, 42, 43);
}
video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
}
</style>
